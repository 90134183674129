<template>
  <div class="tg-post">
    <div class="row" style="display: flex;">
      <book class="col-md-2" v-for="(book, index) in list" :key="index" :book="book" />
    </div>
  </div>
</template>

<script>
import Book from './Book.vue'
import { CommonQuery } from '@/api/commonquery'

export default {
  components: { Book },
  name: 'BookList',
  props: {
    topType: {
      type: Object,
      default: () => {},
    },
    limit: {
      type: Number,
      default: 6,
    },
  },
  data() {
    return {
      list: [],
    }
  },
  watch: {
    topType() {
      this.getData()
    },
  },
  created() {
    if (this.topType) {
      this.getData()
    }
  },
  methods: {
    async getData() {
      let query = {
        model: 'book',
        query: {
          topType: {
            $eq: this.topType._id,
          },
        },
        limit: this.limit,
      }
      let res = await CommonQuery(query)
      this.list = res.data.map((book) => {
        return {
          ...book,
          readed: book.readed || 0,
          to: {
            name: 'bookDetail',
            query: {
              id: book._id,
            },
          },
        }
      })
    },
  },
}
</script>
