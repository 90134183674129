<template>
  <div
    id="tg-homeslider"
    class="tg-homeslider tg-homeslidervtwo tg-haslayout owl-carousel owl-theme"
  >
    <div
      class="item"
      v-for="(banner, index) in banners"
      :key="index"
      data-vide-bg="poster: images/comingsoon-bg.jpg"
      data-vide-options="position: 0% 100%"
    >
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div class="tg-slidercontent">
            <img :src="banner.url" @click="imgClick(banner.sort)" alt="image description" style="width:1200px;object-fit:cover;" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, CarouselItem } from 'element-ui'
export default {
  name: 'Banner',
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
  },
  props: ['banners'],

  watch: {
    banners() {
      // console.log(23)
      this.$nextTick(() => {
        var _tg_homeslider = $('#tg-homeslider')
        _tg_homeslider.owlCarousel({
          items: 1,
          nav: false,
          loop: true,
          dots: true,
          autoplay: true,
        })
      })
    },
  },
  methods: {
    imgClick(sort) {
      if(sort > 0){
        window.location.href = "https://www.fashion-music.com/tzrs/index.html";
      }
    },
  },

}
</script>
