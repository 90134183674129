<template>
  <div class="tg-post" style="display:flex;">
    <div class="banner" v-if="topNews">
      <router-link tag="a" target="_blank" :to="topNews.to">
        <img
          :src="topNews.cover"
          style="width:100%;height:100%;object-fit:cover"
          :title="topNews.title"
        />
      </router-link>
    </div>

    <div class="article-section">
      <div class="title">
        最新动态
      </div>
      <div class="news-list">
        <ul style="width: 321px;">
          <li v-for="news of newsData" :key="news._id" class="new-title">
            <router-link tag="a" target="_blank" :to="news.to" :title="news.title">{{
              news.title
            }}</router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="article-section">
      <div class="title">
        热点动态
      </div>
      <div class="hot-news"
        ><div v-for="news of hotNews" :key="`hot-${news._id}`" style="display:flex;margin:15px 0;">
          <router-link tag="a" target="_blank" :to="news.to"
            ><img :src="news.cover" alt="封面" style="width: 90px;height:90px;object-fit:cover;"
          /></router-link>
          <div style="flex: 1;width: 242px;margin-left:18px;" class="hot-title">
            <router-link tag="a" target="_blank" :to="news.to" :title="news.title">{{
              news.title
            }}</router-link>
            <div class="hot-desc">{{ news.desc }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommonQuery } from '@/api/commonquery'
export default {
  name: 'ArticleSection',
  data() {
    return {
      topNews: null,
      newsData: [],
      hotNews: [],
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      // 获取头条
      this.getArticles({
        query: {
          topType: {
            $ne: '79550af2607333100e0f7ac847e40cb1',
          },
        },
        limit: 1,
        sort: {
          key: 'order',
          order: 'desc',
        },
      }).then((res) => {
        this.topNews = (res || [])[0]
      })

      // 最新
      this.getArticles({
        limit: 5,
        query: {
          topType: {
            $ne: '79550af2607333100e0f7ac847e40cb1',
          },
        },
        sort: {
          key: '_updateTime',
          order: 'desc',
        },
      }).then((res) => {
        this.newsData = res
      })

      // 热门
      this.getArticles({
        limit: 2,
        query: {
          topType: {
            $ne: '79550af2607333100e0f7ac847e40cb1',
          },
        },
        sort: {
          key: 'readed',
          order: 'desc',
        },
      }).then((res) => {
        this.hotNews = res
      })
    },
    async getArticles(params) {
      let res = await CommonQuery({
        model: 'article',
        ...params,
      })
      console.log(res.data)
      return res.data.map((article) => {
        return {
          ...article,
          to: {
            name: 'articleDetail',
            query: {
              id: article._id,
            },
          },
        }
      })
    },
  },
}
</script>

<style scoped>
.banner {
  width: 428px;
  height: 246px;
}

.article-section {
  margin-left: 30px;
}

.title {
  font-size: 20px;
  border-left: 13px solid #f05000;
  padding: 0 10px;
}

.news-list {
  margin-top: 15px;
}

.new-title {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #9cc8c87a;
  padding: 7px 0 8px 0;
}

.new-title:last-child {
  border: none;
}

.hot-title {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hot-desc {
  /* width: 100%; */
  margin-top: 10px;
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #0000007e;
}
</style>
