<template>
  <div>
    <banner :banners="banners" />
    <main id="tg-main" class="tg-main tg-haslayout">
      <section-space
        :section-name="'尚音动态'"
        :model="newsTopType"
        :subModelKey="'topType'"
        :top-model-name="'article'"
      >
        <article-section />
      </section-space>
      <section-space
        v-for="topType of bookTopTypes"
        :key="topType._id"
        :section-name="topType.name"
        :model="topType"
        :subModelKey="'secondType'"
        :top-model-name="'book'"
      >
        <book-list :topType="topType" :limit="6" />
      </section-space>

      <section-space
        :section-name="'视听音乐'"
        :model="videoTopType"
        :subModelKey="'topType'"
        :top-model-name="'video'"
      >
        <card-list :data="videos" :limit="10" />
      </section-space>
    </main>
  </div>
</template>

<script>
import SectionSpace from '../components/SectionSpace'
import BookList from '../components/BookList'
import CardList from '../components/CardList.vue'
import ArticleSection from '../components/ArticleSection.vue'
import Banner from '../components/Banner.vue'
import { CommonQuery, ListQuery } from '../api/commonquery'
export default {
  components: {
    BookList,
    SectionSpace,
    CardList,
    ArticleSection,
    Banner,
  },
  data() {
    return {
      articles: [],
      banners: [],
      videos: [],
      staves: [],
      bookTopTypes: [],
      videoTopType: {
        topType: [],
      },
      newsTopType: {
        topType: [],
      },
    }
  },
  methods: {
    init() {
      this.getBanners()
      this.getBookTopTypes()
      this.getVideoTopTypes()
      this.getNewsTopTypes()
      this.getVideos()
    },
    async getBanners() {
      let res = await CommonQuery({
        model: 'banner',
        query: {},
      })
      this.banners = res.data
    },
    async getBookTopTypes() {
      let data = {
        model: 'bookTopType',
      }
      let res = await ListQuery(data)
      this.bookTopTypes = res.data
    },
    async getVideoTopTypes() {
      let data = {
        model: 'videoTopType',
      }
      let res = await ListQuery(data)
      this.videoTopType.topType = res.data
    },
    async getNewsTopTypes() {
      let data = {
        model: 'articletype',
        query: {
          name: {
            $ne: '固定说明',
          },
        },
      }
      let res = await CommonQuery(data)
      this.newsTopType.topType = res.data
    },
    async getVideos() {
      let res = await CommonQuery({
        model: 'video',
        query: {},
      })
      this.videos = res.data.map((video) => {
        return {
          ...video,
          to: {
            name: 'videoDetail',
            query: {
              id: video._id,
            },
          },
        }
      })
    },
  },
  created() {
    this.init()
  },
}
</script>
